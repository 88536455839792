<template>
  <div class="big_container">
    <div class="box">
      <div class="box_one">
        <div class="title_flex">
          <div class="line"></div>
          <div class="text">机构简介</div>
          <div class="line"></div>
        </div>
      </div>
    </div>
    <div class="one_box">
      <div v-if="details.img" class="img_box">
        <img :src="details.img | ImgFilter" />
      </div>
      <div class="img_box" v-else>
        <img src="../../../assets/img/home/logoes.png" />
      </div>
      <div class="img_two_box">
        <div class="one_text">
          <span class="span_left" v-if="details.type !==111">机构全称</span>
           <span class="span_left" v-else>姓名</span>
          <span class="span_right">{{ details.title }}</span>
        </div>
        <div class="one_text">
          <span class="span_left">联系电话</span>
          <span class="span_right">{{ details.phone }}</span>
        </div>
        <!--<div class="three_text"> <div> <span> 机构标签</span></div> <div> <span> 机构标签</span></div> <div> <span> 机构标签</span></div>  </div>-->
      </div>
      <div class="img_three_box">
        <div class="center_box" @click="zixun">
          <div class="zai_xian">在线咨询>></div>

          <div class="img_boxs">
            <img src="../../../assets/img/home/ke_fu.png" />
          </div>
        </div>
      </div>
    </div>
    <div class="two_box">
      <div class="left_box">
        <span>基本信息</span>
      </div>
      <div class="right_box">
        <div class="right_text">
          <span v-html="details.description"> </span>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="box_one">
        <div class="title_flex">
          <div class="line"></div>
          <div class="text">成果展示</div>
          <div class="line"></div>
        </div>
      </div>
    </div>

    <div class="three_box">
      <el-carousel :interval="4000" type="card" height="200px">
        <el-carousel-item v-for="(item, index) in cheng_guo" :key="index">
          <!--<h3 >标题标题标题标题</h3>-->
          <div class="container_box">
            <p>{{ item.name }}</p>
            <p>{{ item.team }}</p>
            <p>{{ item.phone }}</p>
            <p>{{ item.cooperation }}</p>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import { employer_xqs } from "../../common/js/api";
export default {
  name: "GaoDetail",
  data() {
    return {
      cheng_guo: [],
      details: {},
    };
  },
  created() {
    this.gao_detail();
    localStorage.setItem("id", this.$route.query.ids);
  },
  filters: {
    ImgFilter(value) {
      return "http://hout.kehui.cloud/" + value;
    },
  },
  methods: {
    gao_detail() {
      employer_xqs({
        id: this.$route.query.ids,
      })
        .then((res) => {
          this.cheng_guo = res.data.guo;
          this.details = res.data.list;
          console.log(res.data);
        })
        .catch((err) => {});
    },
    zixun() {
      this.$router.push({
        path: "/chat",
      });
    },
  },
};
</script>

<style scoped lang="less">
.container_box {
  p {
    margin: 30px;
  }
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.big_container {
  background-color: #f8f9fa;
  width: 100%;
  height: auto;
  .box {
    width: 100%;
    margin: 0 auto;
    .box_one {
    }
    .title_flex {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .text {
        font-size: 28px;
        color: #3892eb;
        font-weight: 600;
      }
      .line {
        margin: 100px;
        width: 60px;
        height: 1px;
        background-color: #3892eb;
      }
    }
  }
  .one_box {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .img_box {
      width: 212px;
      height: 138px;
      border-radius: 5px;
      color: rgba(16, 16, 16, 100);
      font-size: 14px;
      text-align: center;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
      font-family: Roboto;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .img_two_box {
      width: 643px;
      height: 137px;
      line-height: 35px;
      border-radius: 5px;
      color: rgba(16, 16, 16, 100);
      font-size: 14px;

      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
      font-family: Roboto;
      border: 1px solid rgba(255, 255, 255, 100);
      .one_text {
        overflow: hidden;
        .span_left {
          display: inline-block;
          margin: 20px 60px 0px 15px;
          color: rgba(16, 16, 16, 100);
          font-size: 18px;
          text-align: left;
          width: 72px;
        }
        .span_right {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
        }
      }
      .three_text {
        display: flex;
        div {
          margin: 15px 30px 0px 15px;
          padding: 2px 5px;
          height: 20px;
          opacity: 0.9;
          border-radius: 4px;
          background-color: rgba(255, 255, 255, 100);
          color: rgba(56, 146, 235, 100);
          font-size: 13px;
          text-align: center;
          border: 1px solid rgba(56, 146, 235, 100);
        }
      }
    }
    .img_three_box {
      width: 284px;
      height: 136px;
      background-image: url("~@/assets/img/home/ji_bg.png");
      background-size: 100% 100%;
      .center_box {
        display: flex;
        align-items: center;
        margin-top: 20px;
        .zai_xian {
          cursor: pointer;
          margin: 30px auto;
          background-color: #fff;
          padding: 2px 5px;
          height: 30px;
          line-height: 25px;
          border-radius: 4px;
          color: rgba(56, 146, 235, 100);
          font-size: 14px;
          text-align: center;
          font-family: Microsoft Yahei;
          border: 1px solid rgba(56, 146, 235, 100);
        }
        .img_boxs {
          margin: 0 auto;
          width: 84px;
          height: 84px;
          img {
            width: 84px;
            height: 84px;
          }
        }
      }
    }
  }
  .two_box {
    width: 1200px;
    margin: 30px auto;
    display: flex;
    justify-content: space-between;
    .left_box {
      width: 89px;
      height: 242px;
      line-height: 20px;
      border-radius: 5px 0px 0px 5px;
      background-color: rgba(56, 146, 235, 100);
      text-align: center;
      border: 1px solid rgba(255, 255, 255, 100);
      span {
        margin-top: 40px;
        line-height: 40px;
        display: inline-block;
        width: 25px;
        height: 108px;
        color: rgba(255, 255, 255, 100);
        font-size: 18px;
        text-align: left;
        font-family: SourceHanSansSC-regular;
      }
    }
    .right_box {
      width: 1111px;
      height: 242px;
      line-height: 20px;
      border-radius: 0px 5px 5px 0px;
      background-color: rgba(255, 255, 255, 100);
      text-align: center;
      border: 1px solid rgba(255, 255, 255, 100);
      .right_text {
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        line-clamp: 6;
        -webkit-box-orient: vertical;
        margin: 30px auto;
        width: 924px;
        /*height: 70px;*/
        line-height: 30px;
        color: rgba(16, 16, 16, 100);
        font-size: 14px;
        text-align: left;
        font-family: SourceHanSansSC-regular;
      }
    }
  }
  .three_box {
    width: 1200px;
    margin: 30px auto;
  }
}
@media screen and (max-width: 1200px) {
	.w{
		width: 98%;
	}
	.big_container .one_box{
		width: 98%;
		flex-wrap: wrap;
		justify-content: flex-start;
	}
	.big_container .two_box{
		width: 98%;
	}
	.big_container .three_box{
		width: 98%;
	}
	.big_container .box .title_flex .text{
		font-size: 2.2rem;
	}
	.big_container .one_box .img_two_box .one_text .span_left{
		font-size: 1.6rem;
		width: auto;
	}
	.big_container .one_box .img_two_box .one_text .span_right{
		font-size: 1.6rem;
	}
	.big_container .one_box .img_two_box{
		flex-grow: 1;
	}
	.big_container .one_box .img_three_box{
		width: 100%;
		height: 7.6rem;
		background-size: cover;
	}
	.big_container .one_box .img_three_box .center_box .zai_xian{
		font-size: 1.8rem;
		height: auto;
		padding: 1rem;
	}
	.big_container .one_box .img_three_box .center_box{
		margin-top: 1rem;
	}
	.big_container .two_box .left_box{
		flex-shrink: 0;
	}
	.big_container .two_box .left_box {
		font-size: 1.8rem;
		text-align: center;
		width: 40px;
		display: none;
	}
	.big_container .two_box .right_box{
		flex-grow: 1;
		width: auto;
		height: auto;
	}
	.big_container .two_box .right_box .right_text{
		width: auto;
		font-size: 1.6rem;
		line-height: 3.6rem;
	}
}
</style>